<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path:'/guidanceManage' }">导诊管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <el-button @click="add('new','')">添加症状</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="id" v-if="false"></el-table-column>
            <el-table-column prop="name" label="症状"></el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <div class="opt">
                    <el-button size="mini" type="primary" icon="el-icon-edit" @click="add('bj',scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除 </el-button>
                    <el-button size="mini" type="warning" icon="el-icon-view" @click="baike(scope.row,scope.$index)">症状百科 </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p> -->
        <el-dialog class="tcform" :title="tctitle" :visible.sync="dialogFormVisible">
            <el-form label-width="170px">
                <el-form-item label="症状">
                    <el-input class="zz" v-model="zz" autocomplete="off" placeholder="请输入症状"></el-input>
                </el-form-item>
                <el-form-item label="平台内科室" prop="dp_id">
                    <el-select filterable class="zzks" v-model="dp_id" placeholder="请选择平台内科室">
                        <el-option v-for="item in keshi" :key="item.value" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false;zz = ''">取 消</el-button>
                <el-button type="primary" @click="sub">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                title: '添加症状',
                dialogFormVisible: false,
                zz: '',
                tctitle: '',
                part_id: '',
                item: '',
                type: '',
                keshi: '',
                dp_id: ''
            }
        },
        mounted() {
            this.title = this.$route.query.name;
            this.part_id = this.$route.query.id;
            this.axios.get("/gu/get_gu_symptom_by_partid?part_id=" + this.part_id + "&sex=")
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.code == 0) {
                        this.list = res.data.result;
                    } else {
                        // this.$message(res.data.msg);
                        console.log(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            this.axios.get('/gu/get_gu_department')
                .then((res) => {
                    if (res.data.code == 0) {
                        this.keshi = res.data.result;
                    } else {
                        console.log(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        methods: {
            handleSizeChange() { },
            handleCurrentChange() { },
            add(type, item) {
                this.dialogFormVisible = true;
                this.item = item;
                this.type = type;
                if (type != 'new') {
                    this.tctitle = '编辑症状';
                    this.zz = item.name;
                    this.dp_id = item.dp_id;
                } else {
                    this.tctitle = '添加症状';
                    this.zz = '';
                    this.dp_id = '';
                }
            },
            del(row, index) {
                this.$confirm('是否删除此症状?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.post("/gu/delete_gu_symptom", this.qs.stringify({ id: row.id }))
                        .then((rs) => {
                            if (rs.data.code == 0) {
                                this.$message({
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.list.splice(index, 1);
                            } else {
                                this.$message(rs.data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            sub() {
                let url = '';
                let form = {
                    name: this.zz,
                    part_id: this.part_id,
                    dp_id: this.dp_id
                }
                let str = '';
                if (this.type == 'new') {
                    url = "/gu/insert_gu_symptom";
                    str = '添加成功';
                } else {
                    url = "/gu/update_gu_symptom";
                    str = "修改成功";
                    form.id = this.item.id;
                }
                // console.log(form);
                this.axios.post(url, this.qs.stringify(form))
                    .then((rs) => {
                        if (rs.data.code == 0) {
                            this.dialogFormVisible = false;
                            this.form = {};
                            this.$message({
                                message: str,
                                type: 'success'
                            });
                            this.reload();
                        } else {
                            this.$message(rs.data.msg);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            baike(row, index) {
                this.$router.push({ path: '/zzCycDetail', query: { bwname: this.title, bwid: this.part_id, zzname: row.name, zzid: row.id } });
            }
        },
    }
</script>
<style>
    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .tcform .zz {
        width: 80%;
        float: left;
    }

    .tcform .zzks {
        float: left;
        width: 50%;
    }
</style>